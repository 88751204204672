import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { LoginpComponent } from './loginp/loginp.component';
import { AuthGuardService } from 'src/shared/services/auth-guard.service';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ShssGuardService } from 'src/shared/services/shss.service';
import { PageNotFoundComponent } from 'src/shared/page-not-found/page-not-found.component';


// routing configuration
const appRoute: Routes = [
    {
    path: '',
    redirectTo: '/shss/home',
    pathMatch: 'full'
    },
    {
        path: 'admin',
        loadChildren: './../admin/admin.module#AdminModule',
        canActivate: [AuthGuardService]
    },
    {
      path: 'shss',
      loadChildren: './../shss/shss.module#ShssModule',
      canActivate: [ShssGuardService]
    },
    {
      path: 'login',
      component: LoginpComponent
    },
    {
      path: 'sidenav',
      component: SidenavComponent
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }

];
@NgModule({
    imports: [RouterModule.forRoot(appRoute)],
    exports: [RouterModule]
})
export class AppRoutingModule {

}

