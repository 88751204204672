import { Component,OnInit } from '@angular/core';
import { AdminService } from 'src/shared/services/admin.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit{
  title = 'frontend';
  constructor(private adms: AdminService) {
  }
  ngOnInit() {

  }
  sidnav() {
    if (localStorage.getItem('token')) {
      return true;
    } else { return false; }
  }
  logout() {
    this.adms.logoutUser();
  }

}
