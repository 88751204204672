import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class AdmissionService {
  url: string;
    constructor(public http: HttpClient) {
        this.url = 'https://murmuring-ravine-64061.herokuapp.com/' + 'admission';

    }

  get() {
      return this.http.get(this.url, this.getOptions());

  }
  getById(id: string) {
      // return this.http.get(this.url + '/' + id, this.getOptions());
      return this.http.get(`${this.url}/${id}`, this.getOptions());
  }
  remove(id) {
    return this.http.delete(`${this.url}/${id}`, this.getOptions());

}

    upload(data: any, files: Array<any>, method) {
      // console.log('data ins ervice ,', data);
      // console.log('files ins ervice ,', files);

      // return Observable.create((observer) => {
      //     const xhr = new XMLHttpRequest();
          const formData = new FormData();

          if (files.length) {
              formData.append('image', files[0], files[0].name);
          }
          for (let key in data) {
            if(data[key]){
              formData.append(key, data[key]);
            }
          }

          // xhr.onreadystatechange = () => {
          //     if (xhr.readyState == 4) {
          //         if (xhr.status == 200) {
          //             observer.next(xhr.response);
          //         } else {
          //             observer.error(xhr.response);
          //         }
          //     }
          // };
          let url = `${this.url}/`
          if (method == 'PUT') {
              url = `${this.url}/${data._id}?token=${localStorage.getItem('token')}`
          }
          return this.http.post(url,formData,{
            reportProgress:true,
            observe:'events'
          }).pipe()
          // xhr.open(method, url,true);
          // console.log('OPENED: ', xhr.status);
          // xhr.onprogress = function () {
          //   console.log('LOADING: ', xhr.status);
          // };

          // xhr.onload = function () {
          //   console.log('DONE: ', xhr.status);
          // };
          // xhr.send(formData);
          // xhr.onprogress = function(event) { // triggers periodically
          //   // event.loaded - how many bytes downloaded
          //   // event.lengthComputable = true if the server sent Content-Length header
          //   // event.total - total number of bytes (if lengthComputable)
          //   console.log(`Received ${event.loaded} of ${event.total}`);
          // };
      // });

  }
  getOptions() {
    if (localStorage.getItem('token')) {
      let headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('token')
        })
    }
    return headers;
    }
    else {
      let headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    return headers;
    }

}


}
