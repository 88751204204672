import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// tslint:disable-next-line: max-line-length
import { MatSidenavModule, MatMenuModule, MatListModule , MatIconModule , MatDividerModule, MatButtonToggleModule , MatButtonModule , MatFormFieldModule , MatInputModule , MatAutocompleteModule, MatRippleModule, MatCheckboxModule, MatCardModule, MatSnackBarModule, MatProgressSpinnerModule,  MatExpansionModule, MatToolbarModule,  MatTabsModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule,MatBadgeModule } from '@angular/material';

const MaterialComponent = [
  MatSidenavModule,
  MatMenuModule,
  MatListModule,
  MatIconModule,
  MatDividerModule,
  MatButtonToggleModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatAutocompleteModule,
  MatRippleModule,
  MatCheckboxModule,
  MatCardModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatToolbarModule,
  MatTabsModule,
  MatFormFieldModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatBadgeModule
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule, MaterialComponent
  ],
  exports : [MaterialComponent]
})
export class MaterialModule { }
