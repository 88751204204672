import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AdminService } from 'src/shared/services/admin.service';
@Component({
  selector: 'app-loginp',
  templateUrl: './loginp.component.html',
  styleUrls: ['./loginp.component.css']
})
export class LoginpComponent implements OnInit {
  hide = true;
  user;
  submitting: boolean = false;
  rememberMe: boolean = false;
  registrationForm: FormGroup;
  constructor(
    public adminService: AdminService,
    public router: Router,
    public fb: FormBuilder,
    public snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.registrationForm = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  get userName() {
    return this.registrationForm.get('userName');
  }
  get password() {
    return this.registrationForm.get('password');
  }
  login() {
    this.submitting=true;
    this.adminService.login(this.registrationForm.value)
    .subscribe(
      (data: any) => {
        // console.log('data:', data);
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user._id));
        this.router.navigate(['/admin/home']);
        this.submitting=false;
        if (this.rememberMe) {
          localStorage.setItem('remember', 'ok');
      }
        this.snackbar.open('Login Successful', 'OK', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      },
      (err) => {
        this.submitting=false;
          // if error ??? send the error data to msg service show Error method
          this.snackbar.open(err.error.message, 'OK', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
      }
    );
  }

  rememberMeChanged() {
    console.log('rememberMeChanged')
    this.rememberMe = !this.rememberMe;
}

}
