import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MaterialModule } from './material/material.module';
import { AdminService } from './services/admin.service';
import { AdmissionService } from './services/admission.service';
import { AuthGuardService } from './services/auth-guard.service';
import { CalendarService } from './services/calendar.service';
import { ContactService } from './services/contact.service';
import { DownloadService } from './services/download.service';
import { NotificationService } from './services/notification.service';
import { PdfService } from './services/pdf.service';
import { PhotoService } from './services/photos.service';
import { StaffService } from './services/staff.service';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { HttpClientModule } from '@angular/common/http';
import { ShssGuardService } from './services/shss.service';


@NgModule({
  declarations: [NavbarComponent, FooterComponent, PageNotFoundComponent],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    HttpClientModule
  ],
  providers: [AdminService,AdmissionService,AuthGuardService,CalendarService,ContactService,DownloadService,NotificationService,PdfService,PhotoService,StaffService,TokenInterceptorService,ShssGuardService],
  exports: [NavbarComponent,FooterComponent,PageNotFoundComponent,MaterialModule]
})
export class SharedModule {
  constructor(){
    console.log("Shared Module")
  }
}
